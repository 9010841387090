<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <h3><span v-if="level.name !== ''">{{ level.name}} <small v-if="level.title !== ''">({{ level.title }})</small></span><span v-else>Level {{ levelNumber }}</span><br /></h3>
        <h4
          v-if="false"
        >
          {{ question.question }}
        </h4>
      </v-col>
      <v-col cols="3">
        <div :class="'roundedAnswer '+levelColor+' lighten-1'" v-if="level.answers[0]" :id="'level-'+levelNumber+'-answer-1'">{{ level.answers[0] }}</div>
      </v-col>
      <v-col cols="3">
        <div :class="'roundedAnswer '+levelColor+' lighten-2'" v-if="level.answers[1]" :id="'level-'+levelNumber+'-answer-2'">{{ level.answers[1] }}</div>
      </v-col>
      <v-col cols="3">
        <div :class="'roundedAnswer '+levelColor+' lighten-3'" v-if="level.answers[2]" :id="'level-'+levelNumber+'-answer-3'">{{ level.answers[2] }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DisplayLevel',
    props: {
      level: Object,
      levelNumber: Number,
      question: Object,
      levelColor: String,
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.roundedAnswer {
  border-radius: 25px;
  padding: 10px;
  margin: 10px;
  z-index: 99;
}
</style>
