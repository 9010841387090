<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div>
        <h2>Bar Chart Example</h2>
        <!-- These are the custom components we'll create -->
        <!-- Values for `my-box` are percentages of the width of the canvas. -->
        <!-- Each bar will take up an equal space of the canvas. -->
        <my-canvas style="width: 100%; height: 600px;">
          <my-box
            v-for="(obj, index) of chartValues"
            :key="index"
            :x1="((index / chartValues.length) * 100)"
            :x2="((index / chartValues.length) * 100) + (100 / chartValues.length)"
            :y1="100"
            :y2="100 - obj.val"
            :color="obj.color"
            :value="obj.val"
          >
          </my-box>
        </my-canvas>
      </div>
      <div>
        <h1 ref="box" class="box"></h1>
        <span>{{x}}, {{y}}</span>
        <h1>Drawing with mousemove event</h1>
        <canvas id="myCanvas" width="578" height="100" @mousemove="showCoordinates"></canvas>
        <svg id="svg_master" viewBox="0 0 500 500">
          <rect
            id="level-1-answer-1"
            x="10"
            y="10"
            rx="10"
            ry="10"
            width="75"
            height="75"
            fill="#FFFCD7"
            stroke="black"
            stroke-width="2px"
          />
          <line x1="50" y1="85" x2="50" y2="115" stroke="red" stroke-width="1" />
          <rect
            id="level-2-answer-1"
            x="10"
            y="115"
            rx="10"
            ry="10"
            width="75"
            height="75"
            fill="#FFFCD7"
            stroke="black"
            stroke-width="2px"
          />
        </svg>
      </div>
      <div style="text-align:center;">
        <div class="col s12">
          <div class="containerbottomwrapper">
            <div class="containercol">
              <h5>Vizion</h5>
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <v-btn
                color="green darken-1"
                text
                @click="runTest"
              >
                Run Test
              </v-btn>
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <v-btn
                color="green darken-1"
                text
                @click="backToVizions"
              >
                Back to Vizions
              </v-btn>
            </div>
          </div>
          <hr>
          <div class="containerwrapper">
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              Center-Left
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              Center-Right
            </div>
            <div class="containercol">&nbsp;</div>
          </div>
        </div>
        <div class="col s12" v-if="vizionData && questions.length > 0">
          <table border="0" cellspacing="0" cellpadding="0">
            <display-level :level="vizionData.level1" :levelNumber="1" :question="questions[0]" levelColor="light-blue" />
            <display-level :level="vizionData.level2" :levelNumber="2" :question="questions[1]" levelColor="green" />
            <display-level :level="vizionData.level3" :levelNumber="3" :question="questions[2]" levelColor="brown" />
            <display-level :level="vizionData.level4" :levelNumber="4" :question="questions[3]" levelColor="grey" />
            <display-level :level="vizionData.level5" :levelNumber="5" :question="questions[4]" levelColor="blue-grey" />
            <display-level :level="vizionData.level6" :levelNumber="6" :question="questions[5]" levelColor="indigo" />
            <display-level :level="vizionData.level7" :levelNumber="7" :question="questions[6]" levelColor="red" />
          </table>
          
        </div>
        <router-view />
      </div>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { mapGetters } from 'vuex'
  import { System } from '@/database/system'
  import { Vizions } from '@/database/deal'
  import { FirebaseResults } from '@/database'
  import $ from 'jquery'
  import DisplayLevel from '@/components/DisplayLevel'
  import { textInShape } from 'text-in-shape'
  // import { TimelineLite } from 'gsap'
  import { gsap } from 'gsap'
  import TextPlugin from 'gsap/TextPlugin'
  import MyCanvas from '@/components/MyCanvas.vue'
  import MyBox from '@/components/MyBox.vue'

  gsap.registerPlugin(TextPlugin)

  export default {
    name: 'SectionVizion',
    components: {
      DisplayLevel,
      MyCanvas,
      MyBox,
    },
    data: () => ({
      vizionId: '',
      vizionData: {},
      questions: [],
      vizionstep: 1,
      canvas: null,
      x: 0,
      y: 0,
      chartValues: [
        { val: 24, color: 'red' },
        { val: 32, color: '#0f0' },
        { val: 66, color: 'rebeccapurple' },
        { val: 1, color: 'green' },
        { val: 28, color: 'blue' },
        { val: 60, color: 'rgba(150, 100, 0, 0.2)' },
      ],
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      ...mapGetters({
        user: 'user',
      }),
      currentUserType () {
        return this.user.data.usertype.toLowerCase()
      },
      currentUserId () {
        return this.user.data.id
      },
    },
    mounted () {
      const { box } = this.$refs
      // const timeline = new gsap.TimelineLite() 
    
      // timeline.to(box, 1, { x: 200, rotation: 90 }) 
      gsap.to(box, { duration: 2, text: 'This is some text to write in the box', delay: 1 })

      this.vizionId = 'LXJgkOTgwwixcDUT3MpE' // this.$route.params.vizionid
      var c = document.getElementById('myCanvas')
      this.canvas = c.getContext('2d')
      this.initialize()
      this.testSVG()

      let dir = 1
      let selectedVal = Math.floor(Math.random() * this.chartValues.length)

      setInterval(() => {
        if (Math.random() > 0.995) dir *= -1
        if (Math.random() > 0.99) selectedVal = Math.floor(Math.random() * this.chartValues.length)

        this.chartValues[selectedVal].val = Math.min(Math.max(this.chartValues[selectedVal].val + dir * 0.5, 0), 100)
      }, 16)
    },
    methods: {
      testSVG () {
        const text = `Lorem ipsum dolor sit amet, nec ut dolorum hendrerit. 
          Ad novum nostro eum, mei no option voluptaria. 
          Senserit referrentur ullamcorper et sed, per semper timeam feugait id. 
          In mea alia meliore, wisi justo his ne. Te essent eripuit appellantur eos. In intellegebat deterruisset vis, at albucius intellegebat sea, ad usu erat impedit. 
          At homero soleat vocibus vim, causae referrentur comprehensam te mea. Ei duo fastidii complectitur, duo legendos euripidis no. Ea habeo invidunt vel. Et omnis probatus senserit eos, accumsan adipisci eum ut. Eu vel mandamus definitiones, usu no probo tempor, vel ad ignota imperdiet reprimique.`
        const smallerText = 'Testing some smaller text'
        console.log(textInShape)
        console.log(textInShape)

        const ids = ['level-1-answer-1']

        ids.forEach((id) => {
          this.showLevelInBox(id, smallerText)
        })
      },
      initialize () {
        // Vizions.findOne(this.vizionId).then(doc => {
        //   this.vizionData = doc.data()
        //   this.vizionData.id = this.vizionId
        //   // console.log(this.vizionData)
        //   System.findOne('questions').then(doc => {
        //     const results = doc.data().data
        //     // console.log(results)
        //     this.questions = results
        //     for (var i = 0; i < this.questions.length; ++i){
        //       this.questions[i].question = this.questions[i].question.replace('{CompanyName}', this.vizionData.CompanyName)
        //     }
        //   })
        // })
      },
      showCoordinates(e) {
        this.x = e.offsetX
        this.y = e.offsetY
      },
      showLevelInBox (id, text) {
        const elem = document.getElementById(id)
        // check if we need to resize
        if (text.length > 50) {
          elem.setAttribute('width', text.length / 50 * 10)
          elem.setAttribute('height', text.length / 50 * 10)
        }
        textInShape(text, elem, {
            align: 'center',
            paddingBottom: 10,
            paddingTop: 10,
            style: {
              fontSize: '10px',
            },
          })
      },
      findObjectByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i]
            }
        }
        return null
      },
      saveVizionData () {
        Vizions.update(this.vizionId, this.vizionData).then(results => {
          // console.log('Status Set.');
        })
      },
      backToVizions () {
        this.$router.push('/vizion/dashboard').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      redirectVizionPretty (id) {
        this.$router.push('/vizion/display/' + id).catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      runTest () {
      },
    },
  }
</script>
<style scoped>
table {
  width: 100%;
  display:table;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: initial;
}
table, th, td {
  border: none;
  line-height: 2.5;
  font-size: 15px;
}
tr {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
  .containerwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  }
  .containerbottomwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
  }
  .containercol {
    text-align: left;
    font-size: small;
    line-height: 34px;
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .roundedAnswer {
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
  }
  canvas {
    border: 1px solid grey;
    /* position: relative;
    z-index: 1;
    display: block; */
  }
  .box { 
    height: auto; 
    width: 160px; 
    background: red; 
  }
</style>
